import { IPublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import ConfigPage from "./Pages/ConfigPage";
import ConfigsPage from "./Pages/ConfigsPage";
import EmployeePage from "./Pages/EmployeePage";
import EmployeesPage from "./Pages/EmployeesPage";
import HomePage from "./Pages/HomePage";
import LoginPage from "./Pages/LoginPage";
import theme from "./theme";

interface ApplicationProps {
  pca: IPublicClientApplication;
}

export default class Application extends Component<ApplicationProps> {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MsalProvider instance={this.props.pca}>
          <BrowserRouter>
            <AuthenticatedTemplate>
              <Header isUserLoggedIn={true} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Header isUserLoggedIn={false} />
            </UnauthenticatedTemplate>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <AuthenticatedTemplate>
                      <HomePage />
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                      <LoginPage pca={this.props.pca} />
                    </UnauthenticatedTemplate>
                  </>
                }
              ></Route>
              <Route path="/configs" element={<ConfigsPage />} />
              <Route path="/config/:id/:tab" element={<ConfigPage />} />
              <Route path="/employees" element={<EmployeesPage />} />
              <Route path="/employee/:id" element={<EmployeePage />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </MsalProvider>
      </ThemeProvider>
    );
  }
}
