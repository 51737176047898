import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { TaxMode } from "../types";

interface TaxModeSelectorProps {
  headerText: string;
  preTaxOptionText: string;
  afterTaxOptionText: string;
  selectedTaxMode?: TaxMode;
  onChange?: (value: TaxMode) => any;
  disabled?: boolean;
}

export default function TaxModeSelector(
  props: TaxModeSelectorProps
): ReactElement {
  const [selectedTaxMode, setSelectedTaxMode] = useState<TaxMode | undefined>(
    props.selectedTaxMode
  );

  useEffect(
    () => setSelectedTaxMode(props.selectedTaxMode),
    [props.selectedTaxMode]
  );

  return (
    <>
      <ReactMarkdown children={props.headerText} />
      <RadioGroup
        value={selectedTaxMode || ""}
        onChange={(e) => {
          const taxMode = e.target.value as TaxMode;
          setSelectedTaxMode(taxMode);
          if (props.onChange) {
            props.onChange(taxMode);
          }
        }}
      >
        <FormControlLabel
          value={TaxMode.PRE_TAX}
          control={<Radio />}
          label={<ReactMarkdown children={props.preTaxOptionText} />}
          disabled={props.disabled}
        />
        <FormControlLabel
          value={TaxMode.AFTER_TAX}
          control={<Radio />}
          label={<ReactMarkdown children={props.afterTaxOptionText} />}
          disabled={props.disabled}
        />
      </RadioGroup>
    </>
  );
}
