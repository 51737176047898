import { TableCell, TextField } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";

interface TableFilterCellProps {
  value: string;
  onChange: (value: string) => any;
}

export default function TableFilterCell(
  props: TableFilterCellProps
): ReactElement {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    const timeout = setTimeout(() => props.onChange(value), 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <TableCell>
      <TextField
        fullWidth
        variant="standard"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </TableCell>
  );
}
