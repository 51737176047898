import { EmployeeInfo, Option, SpecificAmountType, TaxMode } from "./types";

export function dateToISODate(date: string): string {
  if (date) {
    return new Date(date).toISOString().split("T")[0];
  }
  return "";
}

export function nullableBooleanToString(value: boolean | undefined): string {
  switch (value) {
    case true:
      return "yes";
    case false:
      return "no";
    default:
      return "null";
  }
}

export function nullableBooleanFromString(value: string): boolean | undefined {
  switch (value) {
    case "yes":
      return true;
    case "no":
      return false;
    default:
      return undefined;
  }
}

export function formatDateOnly(date: string): string {
  if (date) {
    return new Date(date).toLocaleDateString();
  }

  return "-";
}

export function formatDate(date: string | undefined): string {
  if (date) {
    return new Date(date).toLocaleString();
  }

  return "-";
}

export function saveBlob(blob: Blob, filename: string): void {
  const a: HTMLAnchorElement = document.createElement("a");
  a.download = filename;
  a.href = window.URL.createObjectURL(blob);
  a.dispatchEvent(
    new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    })
  );
  a.remove();
}

export function formatOption(option: Option | undefined): string {
  switch (option) {
    case Option.ZERO:
      return "Zero";
    case Option.MAX:
      return "Max";
    case Option.SPECIFIC_AMOUNT:
      return "Specific";
    default:
      return "-";
  }
}

export function formatTaxMode(taxMode: TaxMode | undefined): string {
  switch (taxMode) {
    case TaxMode.PRE_TAX:
      return "Pre tax";
    case TaxMode.AFTER_TAX:
      return "After tax";
    default:
      return "-";
  }
}

export function formatAmount(data: EmployeeInfo): string {
  if (data.selectedOption === Option.SPECIFIC_AMOUNT) {
    switch (data.selectedAmountType) {
      case SpecificAmountType.DOLLAR:
        return "$" + data.dollars;
      case SpecificAmountType.PERCENTAGE:
        return data.percentage + "%";
    }
  }

  return "-";
}
