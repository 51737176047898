import React from "react";
import ReactDOM from "react-dom";
import Application from "./Application";
import { msalInstance } from "./auth";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <Application pca={msalInstance} />
  </React.StrictMode>,
  document.getElementById("root")
);
