import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ReactElement } from "react";

interface MessageDialogProps {
  open: boolean;
  title: string;
  message: string;
  dismiss: string;
  onDismiss: () => void;
}

export default function MessageDialog(props: MessageDialogProps): ReactElement {
  const handleDismiss = () => {
    props.onDismiss();
  };

  return (
    <Dialog open={props.open} onClose={handleDismiss}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleDismiss}>
          {props.dismiss}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
