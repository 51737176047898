import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import ReactMarkdown from "react-markdown";
import { SpecificAmountType } from "../types";

interface AmountSelectorProps {
  headerText: string;
  dollarAmountOptionText: string;
  percentageOptionText: string;
  selectedAmountType?: SpecificAmountType;
  selectedDollarAmount?: number;
  selectedPercentage?: number;
  onAmountTypeChange?: (value: SpecificAmountType) => any;
  onDollarAmountChange?: (value: number, valid: boolean) => any;
  onPercentageChange?: (value: number, valid: boolean) => any;
  disabled?: boolean;
}

export default function AmountSelector(
  props: AmountSelectorProps
): ReactElement {
  const [selectedAmountType, setSelectedAmountType] =
    useState<SpecificAmountType>();
  const [dollarAmountString, setDollarAmountString] = useState("");
  const [dollarAmountIsValid, setDollarAmountIsValid] = useState(true);
  const [percentageString, setPercentageString] = useState("");
  const [percentageIsValid, setPercentageIsValid] = useState(true);

  const validateDollarAmount = useCallback(
    (dollars: number, dollarsString: string) => {
      // Disallow leading zeros, allow up to 4 decimal places
      const regex: RegExp = /^[1-9][0-9]{0,4}(.[0-9]{0,4})?$/;
      return regex.test(dollarsString) && dollars >= 1 && dollars <= 50000;
    },
    []
  );

  const validatePercentage = useCallback(
    (percent: number, percentString: string) => {
      // Disallow leading zeros, integers only
      const regex: RegExp = /^[1-9][0-9]{0,2}$/;
      return regex.test(percentString) && percent >= 1 && percent <= 100;
    },
    []
  );

  useEffect(() => {
    setSelectedAmountType(props.selectedAmountType);
  }, [props.selectedAmountType]);

  useEffect(() => {
    if (props.selectedDollarAmount) {
      setDollarAmountString(String(props.selectedDollarAmount));
      setDollarAmountIsValid(
        validateDollarAmount(
          props.selectedDollarAmount,
          String(props.selectedDollarAmount)
        )
      );
    } else {
      setDollarAmountString("");
      setDollarAmountIsValid(true);
    }
  }, [props.selectedDollarAmount, validateDollarAmount]);

  useEffect(() => {
    if (props.selectedPercentage) {
      setPercentageString(String(props.selectedPercentage));
      setPercentageIsValid(
        validatePercentage(
          props.selectedPercentage,
          String(props.selectedPercentage)
        )
      );
    } else {
      setPercentageString("");
      setPercentageIsValid(true);
    }
  }, [props.selectedPercentage, validatePercentage]);

  const handleAmountTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: SpecificAmountType = event.target.value as SpecificAmountType;
    setSelectedAmountType(value);
    if (props.onAmountTypeChange) {
      props.onAmountTypeChange(value);
    }

    switch (value) {
      case SpecificAmountType.DOLLAR:
        setPercentageString("");
        setPercentageIsValid(false);
        if (props.onPercentageChange) {
          props.onPercentageChange(0, false);
        }
        break;
      case SpecificAmountType.PERCENTAGE:
        setDollarAmountString("");
        setDollarAmountIsValid(false);
        if (props.onDollarAmountChange) {
          props.onDollarAmountChange(0, false);
        }
        break;
    }
  };

  const handleDollarAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: number = parseFloat(event.target.value);
    const isValid = validateDollarAmount(value, event.target.value);
    setDollarAmountString(event.target.value);
    setDollarAmountIsValid(isValid);
    if (props.onDollarAmountChange) {
      props.onDollarAmountChange(value, isValid);
    }
  };

  const handlePercentageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: number = parseFloat(event.target.value);
    const isValid: boolean = validatePercentage(value, event.target.value);
    setPercentageString(event.target.value);
    setPercentageIsValid(isValid);
    if (props.onPercentageChange) {
      props.onPercentageChange(value, isValid);
    }
  };

  return (
    <>
      <ReactMarkdown children={props.headerText} />
      <RadioGroup
        value={selectedAmountType || ""}
        onChange={handleAmountTypeChange}
      >
        <FormControlLabel
          value={SpecificAmountType.DOLLAR}
          control={<Radio />}
          label={props.dollarAmountOptionText}
          disabled={props.disabled}
        />
        <TextField
          disabled={
            props.disabled || selectedAmountType !== SpecificAmountType.DOLLAR
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={dollarAmountString}
          error={!dollarAmountIsValid}
          onChange={handleDollarAmountChange}
        />
        <FormControlLabel
          value={SpecificAmountType.PERCENTAGE}
          control={<Radio />}
          label={props.percentageOptionText}
          disabled={props.disabled}
        />
        <TextField
          disabled={
            props.disabled ||
            selectedAmountType !== SpecificAmountType.PERCENTAGE
          }
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          value={percentageString}
          error={!percentageIsValid}
          onChange={handlePercentageChange}
        />
      </RadioGroup>
    </>
  );
}
