import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Option, SpecificAmountType, TaxMode } from "../types";
import AmountSelector from "./AmountSelector";
import TaxModeSelector from "./TaxModeSelector";

interface MainFormProps {
  headerText: string;
  optionZeroText: string;
  optionZeroDesc: string;
  optionMaxText: string;
  optionMaxDesc: string;
  optionCustomText: string;
  optionCustomDesc: string;
  taxModeHeaderText: string;
  taxModePreTaxText: string;
  taxModeAfterTaxText: string;
  customHeaderText: string;
  customDollarsText: string;
  customPercentageText: string;
  footerText: string;
  selectedOption?: Option;
  selectedTaxMode?: TaxMode;
  selectedAmountType?: SpecificAmountType;
  selectedDollarAmount?: number;
  selectedPercentage?: number;
  onOptionChange?: (value: Option) => any;
  onTaxModeChange?: (value: TaxMode) => any;
  onAmountTypeChange?: (value: SpecificAmountType) => any;
  onDollarAmountChange?: (value: number, valid: boolean) => any;
  onPercentageChange?: (value: number, valid: boolean) => any;
  disabled?: boolean;
}

export default function MainForm(props: MainFormProps): ReactElement {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    props.selectedOption
  );
  const [selectedTaxMode, setSelectedTaxMode] = useState<TaxMode | undefined>(
    props.selectedTaxMode
  );
  const [selectedAmountType, setSelectedAmountType] = useState<
    SpecificAmountType | undefined
  >(props.selectedAmountType);
  const [selectedDollarAmount, setSelectedDollarAmount] = useState<
    number | undefined
  >(props.selectedDollarAmount);
  const [selectedPercentage, setSelectedPercentage] = useState<
    number | undefined
  >(props.selectedPercentage);

  useEffect(
    () => setSelectedOption(props.selectedOption),
    [props.selectedOption]
  );

  useEffect(
    () => setSelectedTaxMode(props.selectedTaxMode),
    [props.selectedTaxMode]
  );

  useEffect(
    () => setSelectedAmountType(props.selectedAmountType),
    [props.selectedAmountType]
  );

  useEffect(
    () => setSelectedDollarAmount(props.selectedDollarAmount),
    [props.selectedDollarAmount]
  );

  useEffect(
    () => setSelectedPercentage(props.selectedPercentage),
    [props.selectedPercentage]
  );

  return (
    <>
      <ReactMarkdown children={props.headerText} />
      <RadioGroup
        value={selectedOption || ""}
        onChange={(e) => {
          const option = e.target.value as Option;
          setSelectedOption(option);
          if (props.onOptionChange) {
            props.onOptionChange(option);
          }
        }}
      >
        <FormControlLabel
          value={Option.ZERO}
          control={<Radio />}
          label={<ReactMarkdown children={props.optionZeroText} />}
          disabled={props.disabled}
        />
        {selectedOption === Option.ZERO && (
          <Box ml={4}>
            <ReactMarkdown children={props.optionZeroDesc} />
          </Box>
        )}
        <FormControlLabel
          value={Option.MAX}
          control={<Radio />}
          label={<ReactMarkdown children={props.optionMaxText} />}
          disabled={props.disabled}
        />
        {selectedOption === Option.MAX && (
          <Box ml={4}>
            <ReactMarkdown children={props.optionMaxDesc} />
            <TaxModeSelector
              disabled={props.disabled}
              headerText={props.taxModeHeaderText}
              preTaxOptionText={props.taxModePreTaxText}
              afterTaxOptionText={props.taxModeAfterTaxText}
              selectedTaxMode={selectedTaxMode}
              onChange={(v) => {
                setSelectedTaxMode(v);
                if (props.onTaxModeChange) {
                  props.onTaxModeChange(v);
                }
              }}
            />
          </Box>
        )}
        <FormControlLabel
          disabled={props.disabled}
          value={Option.SPECIFIC_AMOUNT}
          control={<Radio />}
          label={<ReactMarkdown children={props.optionCustomText} />}
        />
        {selectedOption === Option.SPECIFIC_AMOUNT && (
          <Box ml={4}>
            <ReactMarkdown children={props.optionCustomDesc} />
            <TaxModeSelector
              disabled={props.disabled}
              headerText={props.taxModeHeaderText}
              preTaxOptionText={props.taxModePreTaxText}
              afterTaxOptionText={props.taxModeAfterTaxText}
              selectedTaxMode={selectedTaxMode}
              onChange={(v) => {
                setSelectedTaxMode(v);
                if (props.onTaxModeChange) {
                  props.onTaxModeChange(v);
                }
              }}
            />
            <AmountSelector
              disabled={props.disabled}
              headerText={props.customHeaderText}
              dollarAmountOptionText={props.customDollarsText}
              percentageOptionText={props.customPercentageText}
              selectedAmountType={selectedAmountType}
              selectedDollarAmount={selectedDollarAmount}
              selectedPercentage={selectedPercentage}
              onAmountTypeChange={(v) => {
                setSelectedAmountType(v);
                if (props.onAmountTypeChange) {
                  props.onAmountTypeChange(v);
                }
              }}
              onDollarAmountChange={(value, valid) => {
                setSelectedDollarAmount(value);
                if (props.onDollarAmountChange) {
                  props.onDollarAmountChange(value, valid);
                }
              }}
              onPercentageChange={(value, valid) => {
                setSelectedPercentage(value);
                if (props.onPercentageChange) {
                  props.onPercentageChange(value, valid);
                }
              }}
            />
          </Box>
        )}
      </RadioGroup>
      <ReactMarkdown children={props.footerText} />
    </>
  );
}
