import { IPublicClientApplication } from "@azure/msal-browser";
import { Button, Container, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { ReactElement } from "react-markdown/lib/react-markdown";

interface LoginPageProps {
  pca: IPublicClientApplication;
}

export default function LoginPage(props: LoginPageProps): ReactElement {
  const loginHandler = () => {
    props.pca.loginRedirect().catch((e: any) => console.error(e));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper style={{ padding: "1rem", marginTop: "3rem" }} elevation={6}>
        <Box display="flex" justifyContent="center" my={6}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={loginHandler}
          >
            Login to the Application
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
