import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import EmployeeInfoForm from "../Components/EmployeeInfoForm";
import MainForm from "../Components/MainForm";
import PrintableForm from "../Components/PrintableForm";
import SelectConfig from "../Components/SelectConfig";
import { apiGetAcknowledgment, apiSubmitAcknowledgment } from "../api";
import { useSearchParamNumber } from "../hooks";
import { Option, SpecificAmountType, TaxMode } from "../types";

export default function HomePage(): ReactElement {
  const [configId, setConfigId] = useSearchParamNumber("configId", 0);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedConfigId, setSelectedConfigId] = useState(0);
  const [year, setYear] = useState(0);
  const [empCode, setEmpCode] = useState("");
  const [empName, setEmpName] = useState("");
  const [empEmail, setEmpEmail] = useState("");
  const [divName, setDivName] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [optionZeroText, setOptionZeroText] = useState("");
  const [optionZeroDesc, setOptionZeroDesc] = useState("");
  const [optionMaxText, setOptionMaxText] = useState("");
  const [optionMaxDesc, setOptionMaxDesc] = useState("");
  const [optionCustomText, setOptionCustomText] = useState("");
  const [optionCustomDesc, setOptionCustomDesc] = useState("");
  const [taxModeHeaderText, setTaxModeHeaderText] = useState("");
  const [taxModePreTaxText, setTaxModePreTaxText] = useState("");
  const [taxModeAfterTaxText, setTaxModeAfterTaxText] = useState("");
  const [customHeaderText, setCustomHeaderText] = useState("");
  const [customDollarsText, setCustomDollarsText] = useState("");
  const [customPercentageText, setCustomPercentageText] = useState("");
  const [footerText, setFooterText] = useState("");
  const [ackDate, setAckDate] = useState<Date>();
  const [selectedOption, setSelectedOption] = useState<Option>();
  const [selectedTaxMode, setSelectedTaxMode] = useState<TaxMode>();
  const [selectedAmountType, setSelectedAmountType] =
    useState<SpecificAmountType>();
  const [dollars, setDollars] = useState<number>();
  const [percentage, setPercentage] = useState<number>();
  const [isDollarsValid, setIsDollarsValid] = useState(false);
  const [isPercentageValid, setIsPercentageValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsLoaded(false);
    apiGetAcknowledgment(configId || undefined)
      .then((data) => {
        setSelectedConfigId(data.configId);

        setIsLoading(false);

        setYear(data.year);
        setEmpCode(data.empCode);
        setEmpName(data.empName);
        setEmpEmail(data.empEmail);
        setDivName(data.divName);
        setHeaderText(data.headerText);
        setOptionZeroText(data.optionZeroText);
        setOptionZeroDesc(data.optionZeroDesc);
        setOptionMaxText(data.optionMaxText);
        setOptionMaxDesc(data.optionMaxDesc);
        setOptionCustomText(data.optionCustomText);
        setOptionCustomDesc(data.optionCustomDesc);
        setTaxModeHeaderText(data.taxModeHeaderText);
        setTaxModePreTaxText(data.taxModePreTaxText);
        setTaxModeAfterTaxText(data.taxModeAfterTaxText);
        setCustomHeaderText(data.customHeaderText);
        setCustomDollarsText(data.customDollarsText);
        setCustomPercentageText(data.customPercentageText);
        setFooterText(data.footerText);
        setAckDate(data.ackDate ? new Date(data.ackDate) : undefined);
        setSelectedOption(data.selectedOption);
        setSelectedTaxMode(data.selectedTaxMode);
        setSelectedAmountType(data.selectedAmountType);
        setDollars(data.dollars);
        setPercentage(data.percentage);
        setIsDollarsValid(Boolean(data.dollars));
        setIsPercentageValid(Boolean(data.percentage));
        setErrorMessage("");
        setSubmitting(false);
        setSubmitted(Boolean(data.ackDate));
        setCanSubmit(data.canSubmit);

        setIsLoaded(true);
      })
      .catch((e) => {
        setSelectedConfigId(configId);

        setIsLoading(false);
        setErrorMessage(e.message);

        setIsLoaded(false);
      });
  }, [configId]);

  const isValid = () => {
    switch (selectedOption) {
      case Option.ZERO:
        return true;
      case Option.MAX:
        return Boolean(selectedTaxMode);
      case Option.SPECIFIC_AMOUNT:
        return (
          Boolean(selectedTaxMode) &&
          ((selectedAmountType === SpecificAmountType.DOLLAR &&
            isDollarsValid) ||
            (selectedAmountType === SpecificAmountType.PERCENTAGE &&
              isPercentageValid))
        );
      default:
        return false;
    }
  };

  const submit = () => {
    setSubmitting(true);
    apiSubmitAcknowledgment({
      option: selectedOption,
      taxMode: selectedOption !== Option.ZERO ? selectedTaxMode : undefined,
      amountType:
        selectedOption === Option.SPECIFIC_AMOUNT
          ? selectedAmountType
          : undefined,
      dollars:
        selectedOption === Option.SPECIFIC_AMOUNT &&
        selectedAmountType === SpecificAmountType.DOLLAR
          ? dollars
          : undefined,
      percentage:
        selectedOption === Option.SPECIFIC_AMOUNT &&
        selectedAmountType === SpecificAmountType.PERCENTAGE
          ? percentage
          : undefined,
    })
      .then(() => {
        setSubmitting(false);
        setSubmitted(true);
        setCanSubmit(false);
        setAckDate(new Date());
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setSubmitting(false);
      });
  };

  return (
    <>
      <Box displayPrint="none">
        <Backdrop open={isLoading}>
          <CircularProgress />
        </Backdrop>
        <Container maxWidth="lg" style={{ marginTop: 20 }}>
          <Box textAlign="right" marginBottom={2}>
            <SelectConfig
              configId={selectedConfigId}
              onChange={(v) => setConfigId(v)}
            />
          </Box>
          <Paper elevation={3}>
            <Box p={3}>
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
              {isLoaded && (
                <>
                  <MainForm
                    disabled={submitting || !canSubmit}
                    headerText={headerText}
                    optionZeroText={optionZeroText}
                    optionZeroDesc={optionZeroDesc}
                    optionMaxText={optionMaxText}
                    optionMaxDesc={optionMaxDesc}
                    optionCustomText={optionCustomText}
                    optionCustomDesc={optionCustomDesc}
                    taxModeHeaderText={taxModeHeaderText}
                    taxModePreTaxText={taxModePreTaxText}
                    taxModeAfterTaxText={taxModeAfterTaxText}
                    customHeaderText={customHeaderText}
                    customDollarsText={customDollarsText}
                    customPercentageText={customPercentageText}
                    footerText={footerText}
                    selectedOption={selectedOption}
                    selectedTaxMode={selectedTaxMode}
                    selectedAmountType={selectedAmountType}
                    selectedDollarAmount={dollars}
                    selectedPercentage={percentage}
                    onOptionChange={(v) => setSelectedOption(v)}
                    onTaxModeChange={(v) => setSelectedTaxMode(v)}
                    onAmountTypeChange={(v) => setSelectedAmountType(v)}
                    onDollarAmountChange={(value, valid) => {
                      setDollars(value);
                      setIsDollarsValid(valid);
                    }}
                    onPercentageChange={(value, valid) => {
                      setPercentage(value);
                      setIsPercentageValid(valid);
                    }}
                  />
                  <EmployeeInfoForm
                    year={year}
                    empCode={empCode}
                    empName={empName}
                    empEmail={empEmail}
                    divName={divName}
                    ackDate={ackDate}
                  />
                  <Box mt={2}>
                    {submitting ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        disabled={!canSubmit || !isValid()}
                        variant="contained"
                        onClick={submit}
                      >
                        Submit
                      </Button>
                    )}
                    {submitted && (
                      <Button
                        variant="contained"
                        onClick={() => window.print()}
                        style={{ marginLeft: 10 }}
                      >
                        Print
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Paper>
        </Container>
      </Box>
      <Box display="none" displayPrint="contents">
        <PrintableForm
          headerText={headerText}
          optionZeroText={optionZeroText}
          optionZeroDesc={optionZeroDesc}
          optionMaxText={optionMaxText}
          optionMaxDesc={optionMaxDesc}
          optionCustomText={optionCustomText}
          optionCustomDesc={optionCustomDesc}
          taxModeHeaderText={taxModeHeaderText}
          taxModePreTaxText={taxModePreTaxText}
          taxModeAfterTaxText={taxModeAfterTaxText}
          customHeaderText={customHeaderText}
          customDollarsText={customDollarsText}
          customPercentageText={customPercentageText}
          footerText={footerText}
          selectedOption={selectedOption}
          selectedTaxMode={selectedTaxMode}
          selectedAmountType={selectedAmountType}
          selectedDollarAmount={dollars}
          selectedPercentage={percentage}
          year={year}
          empCode={empCode}
          empName={empName}
          empEmail={empEmail}
          divName={divName}
          ackDate={ackDate}
        />
      </Box>
    </>
  );
}
