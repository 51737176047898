import { MenuItem, Select } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { fetchConfigs } from "../api";
import { Config, ConfigOrder } from "../types";

interface SelectConfigProps {
  fullWidth?: boolean;
  variant?: "standard" | "outlined" | "filled";
  emptyOptionLabel?: string;
  configId: number;
  onChange: (configId: number) => void;
}

export default function SelectConfig(props: SelectConfigProps): ReactElement {
  const [configs, setConfigs] = useState<Config[]>([]);

  useEffect(() => {
    fetchConfigs(ConfigOrder.YEAR_DESC, 0, 100)
      .then((data) => setConfigs(data))
      .catch(() => setConfigs([]));
  }, []);

  return (
    <Select
      size="small"
      variant={props.variant}
      fullWidth={props.fullWidth}
      value={props.configId}
      onChange={(e) => props.onChange(Number(e.target.value))}
    >
      {props.emptyOptionLabel && (
        <MenuItem value="0">{props.emptyOptionLabel}</MenuItem>
      )}
      {configs.map((x) => (
        <MenuItem value={x.id}>{x.year}</MenuItem>
      ))}
    </Select>
  );
}
