import { getAccessToken } from "./auth";
import {
  Config,
  ConfigData,
  ConfigOrder,
  CurrentUser,
  EmployeeInfo,
  EmployeeInfoOrder,
  EmployeeInfoWithConfig,
  GetAcknowledgmentResponse,
  Option,
  SpecificAmountType,
  SubmitAcknowledgmentRequest,
  TaxMode,
} from "./types";

const ApiPrefix: string = process.env.REACT_APP_API_URL_PREFIX || "";

export interface ProblemDetails {
  title: string;
  status: number;
  errors: Record<string, string>;
}

type Params = Record<string, string>;

interface ApiCallParams {
  method: string;
  path: string;
  body?: object;
  params?: Params;
}

function formUrl(path: string, params?: Params): string {
  const url: URL = new URL(path, ApiPrefix);
  for (const name in params) {
    url.searchParams.append(name, params[name]);
  }
  return url.toString();
}

async function apiCallInner(params: ApiCallParams): Promise<Response> {
  const url: string = formUrl(params.path, params.params);
  const accessToken: string = await getAccessToken();
  const response: Response = await fetch(url, {
    method: params.method,
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params.body),
  });
  return response;
}

export async function apiGetCurrentUser(): Promise<CurrentUser> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "currentuser",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: CurrentUser = await response.json();
  return data;
}

export async function apiGetAcknowledgment(
  configId?: number
): Promise<GetAcknowledgmentResponse> {
  const params: Params = {};
  if (typeof configId === "number") {
    params["configId"] = configId.toString();
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "acknowledgment",
    params: params,
  });
  if (response.status === 400) {
    throw new Error("You are not eligible in the selected year.");
  } else if (!response.ok) {
    const text: string = await response.text();
    throw Error(text || response.statusText);
  }
  const data: GetAcknowledgmentResponse = await response.json();
  return data;
}

export async function apiSubmitAcknowledgment(
  data: SubmitAcknowledgmentRequest
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "PUT",
    path: "acknowledgment",
    body: data,
  });
  if (response.status === 400) {
    throw Error(await response.text());
  } else if (!response.ok) {
    throw Error(response.statusText);
  }
}

export async function fetchConfigCount(): Promise<number> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "config/count",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: string = await response.text();
  return parseInt(data, 10);
}

export async function fetchConfigs(
  order: ConfigOrder | undefined,
  skip: number,
  take: number
): Promise<Config[]> {
  const params: Params = {};
  if (order) {
    params["order"] = order.toString();
  }
  params["skip"] = skip.toString();
  params["take"] = take.toString();
  const response: Response = await apiCallInner({
    method: "GET",
    path: "config",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Config[] = await response.json();
  return data;
}

export async function fetchConfig(configId: number): Promise<Config> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "config/" + configId,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Config = await response.json();
  return data;
}

export async function fetchLatestConfig(): Promise<Config> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "config/latest",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Config = await response.json();
  return data;
}

export async function apiCreateConfig(
  configData: ConfigData
): Promise<ProblemDetails | null> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "config",
    body: configData,
  });
  if (!response.ok) {
    const data: ProblemDetails = await response.json();
    return data;
  }
  return null;
}

export async function apiUpdateConfig(
  configId: number,
  configData: ConfigData
): Promise<ProblemDetails | null> {
  const response: Response = await apiCallInner({
    method: "PUT",
    path: "config/" + configId,
    body: configData,
  });
  if (!response.ok) {
    const data: ProblemDetails = await response.json();
    return data;
  }
  return null;
}

export async function apiDeleteConfig(configId: number): Promise<void> {
  const response: Response = await apiCallInner({
    method: "DELETE",
    path: "config/" + configId,
  });
  if (!response.ok) throw Error(response.statusText);
}

export async function fetchEmployeeInfoCount(
  configId: number | undefined,
  empCode: string | undefined,
  empName: string | undefined,
  empEmail: string | undefined,
  divName: string | undefined,
  option: Option | undefined,
  taxMode: TaxMode | undefined,
  amountType: SpecificAmountType | undefined
): Promise<number> {
  const params: Params = {};
  if (typeof configId === "number") {
    params["configId"] = configId.toString();
  }
  if (typeof empCode === "string") {
    params["empCodeContains"] = empCode;
  }
  if (typeof empName === "string") {
    params["empNameContains"] = empName;
  }
  if (typeof empEmail === "string") {
    params["empEmailContains"] = empEmail;
  }
  if (typeof divName === "string") {
    params["divNameContains"] = divName;
  }
  if (typeof option === "string") {
    params["option"] = option;
  }
  if (typeof taxMode === "string") {
    params["taxMode"] = taxMode;
  }
  if (typeof amountType === "string") {
    params["amountType"] = amountType;
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "employeeinfo/count",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: string = await response.text();
  return parseInt(data, 10);
}

export async function fetchEmployeeInfos(
  configId: number | undefined,
  empCode: string | undefined,
  empName: string | undefined,
  empEmail: string | undefined,
  divName: string | undefined,
  option: Option | undefined,
  taxMode: TaxMode | undefined,
  amountType: SpecificAmountType | undefined,
  order: EmployeeInfoOrder | undefined,
  skip: number,
  take: number
): Promise<EmployeeInfo[]> {
  const params: Params = {};
  if (typeof configId === "number") {
    params["configId"] = configId.toString();
  }
  if (typeof empCode === "string") {
    params["empCodeContains"] = empCode;
  }
  if (typeof empName === "string") {
    params["empNameContains"] = empName;
  }
  if (typeof empEmail === "string") {
    params["empEmailContains"] = empEmail;
  }
  if (typeof divName === "string") {
    params["divNameContains"] = divName;
  }
  if (typeof option === "string") {
    params["option"] = option;
  }
  if (typeof taxMode === "string") {
    params["taxMode"] = taxMode;
  }
  if (typeof amountType === "string") {
    params["amountType"] = amountType;
  }
  if (typeof order === "string") {
    params["order"] = order;
  }
  params["skip"] = skip.toString();
  params["take"] = take.toString();
  const response: Response = await apiCallInner({
    method: "GET",
    path: "employeeinfo",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: EmployeeInfo[] = await response.json();
  return data;
}

export async function fetchEmployeeInfoWithConfig(
  id: number
): Promise<EmployeeInfoWithConfig> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: `employeeinfo/${id}/withconfig`,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: EmployeeInfoWithConfig = await response.json();
  return data;
}

export async function apiUpdateEmployeeInfo(
  id: number,
  isIncluded: boolean
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "PATCH",
    path: `employeeinfo/${id}`,
    body: { isIncluded: isIncluded },
  });
  if (!response.ok) {
    throw Error(await response.text());
  }
}

export async function apiUpdateEmployeeInfos(
  ids: number[],
  isIncluded: boolean
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "PATCH",
    path: "employeeinfo",
    body: { ids: ids, isIncluded: isIncluded },
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
}

export async function apiSyncEmployeesForConfig(
  configId: number
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "sync/employeesforconfig",
    params: { configId: configId.toString() },
  });
  if (!response.ok) {
    throw Error(await response.text());
  }
}

export async function apiExportEmployeeInfoAsExcel(
  configId: number | undefined,
  empCode: string | undefined,
  empName: string | undefined,
  empEmail: string | undefined,
  divName: string | undefined,
  option: Option | undefined,
  taxMode: TaxMode | undefined,
  amountType: SpecificAmountType | undefined,
  order: EmployeeInfoOrder | undefined
): Promise<Blob> {
  const params: Params = {};
  if (typeof configId === "number") {
    params["configId"] = configId.toString();
  }
  if (typeof empCode === "string") {
    params["empCodeContains"] = empCode;
  }
  if (typeof empName === "string") {
    params["empNameContains"] = empName;
  }
  if (typeof empEmail === "string") {
    params["empEmailContains"] = empEmail;
  }
  if (typeof divName === "string") {
    params["divNameContains"] = divName;
  }
  if (typeof option === "string") {
    params["option"] = option;
  }
  if (typeof taxMode === "string") {
    params["taxMode"] = taxMode;
  }
  if (typeof amountType === "string") {
    params["amountType"] = amountType;
  }
  if (typeof order === "string") {
    params["order"] = order;
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "export/Employees.xlsx",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const blob: Blob = await response.blob();
  return blob;
}
