import { AccountCircle, Home, People, Settings } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Component } from "react";
import { Link } from "react-router-dom";
import { apiGetCurrentUser } from "../api";
import { CurrentUser } from "../types";

interface HeaderProps {
  isUserLoggedIn: boolean;
}

interface HeaderState {
  isDrawerOpen: boolean;
  userEmail: string;
  isUserAdmin: boolean;
  latestConfigId: number | undefined;
}

export default class Header extends Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);

    this.state = {
      isDrawerOpen: false,
      userEmail: "",
      isUserAdmin: false,
      latestConfigId: undefined,
    };
  }

  async componentDidMount() {
    if (this.props.isUserLoggedIn) {
      try {
        const data: CurrentUser = await apiGetCurrentUser();

        this.setState({
          userEmail: data.email,
          isUserAdmin: data.isAdmin,
          latestConfigId: data.latestConfigId,
        });
      } catch {
        this.setState({ userEmail: "", isUserAdmin: false });
      }
    }
  }

  handleButtonClick() {
    this.setState({ isDrawerOpen: true });
  }

  handleDrawerClose() {
    this.setState({ isDrawerOpen: false });
  }

  render() {
    return (
      <Box displayPrint="none">
        <AppBar
          position="static"
          elevation={3}
          style={{ backgroundColor: grey[50] }}
        >
          <Toolbar>
            <Link to="/">
              <img
                src={process.env.PUBLIC_URL + "/dm_logo.png"}
                alt="D&amp;M Logo"
                style={{
                  height: "52px",
                  verticalAlign: "middle",
                  paddingRight: "2rem",
                }}
              />
            </Link>

            <Typography
              variant="subtitle2"
              color="inherit"
              noWrap
              style={{ color: grey[900] }}
              sx={{ flexGrow: 1 }}
            >
              D&amp;M 401k Election Letter
            </Typography>

            {this.props.isUserLoggedIn && (
              <>
                <Typography variant="subtitle2" style={{ color: grey[900] }}>
                  {this.state.userEmail}
                </Typography>

                <IconButton onClick={this.handleButtonClick.bind(this)}>
                  <AccountCircle />
                </IconButton>
              </>
            )}
          </Toolbar>
        </AppBar>

        <Drawer
          anchor="right"
          open={this.state.isDrawerOpen}
          onClose={this.handleDrawerClose.bind(this)}
        >
          <List style={{ width: 250 }}>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/"
                onClick={this.handleDrawerClose.bind(this)}
              >
                <ListItemIcon>
                  <Home />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>
            {this.state.isUserAdmin && (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    to="/configs"
                    onClick={this.handleDrawerClose.bind(this)}
                  >
                    <ListItemIcon>
                      <Settings />
                    </ListItemIcon>
                    <ListItemText primary="Configs" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    to={
                      this.state.latestConfigId
                        ? "/employees?configId=" + this.state.latestConfigId
                        : "/employees"
                    }
                    onClick={this.handleDrawerClose.bind(this)}
                  >
                    <ListItemIcon>
                      <People />
                    </ListItemIcon>
                    <ListItemText primary="Employees" />
                  </ListItemButton>
                </ListItem>
              </>
            )}
          </List>
        </Drawer>
      </Box>
    );
  }
}
