import { AuthenticatedTemplate } from "@azure/msal-react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { MouseEventHandler, useEffect, useState } from "react";
import { ReactElement } from "react-markdown/lib/react-markdown";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  apiCreateConfig,
  apiUpdateConfig,
  fetchConfig,
  fetchLatestConfig,
} from "../api";
import MainForm from "../Components/MainForm";
import MessageDialog from "../Components/MessageDialog";
import { Config, ConfigData } from "../types";
import { dateToISODate } from "../utils";

enum ConfigPageTab {
  EDIT = "edit",
  PREVIEW = "preview",
}

export default function ConfigPage(): ReactElement {
  const navigate = useNavigate();

  const { id, tab } = useParams();

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadedId, setLoadedId] = useState(0);
  const [year, setYear] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [optionZeroText, setOptionZeroText] = useState("");
  const [optionZeroDesc, setOptionZeroDesc] = useState("");
  const [optionMaxText, setOptionMaxText] = useState("");
  const [optionMaxDesc, setOptionMaxDesc] = useState("");
  const [optionCustomText, setOptionCustomText] = useState("");
  const [optionCustomDesc, setOptionCustomDesc] = useState("");
  const [taxModeHeaderText, setTaxModeHeaderText] = useState("");
  const [taxModePreTaxText, setTaxModePreTaxText] = useState("");
  const [taxModeAfterTaxText, setTaxModeAfterTaxText] = useState("");
  const [customHeaderText, setCustomHeaderText] = useState("");
  const [customDollarsText, setCustomDollarsText] = useState("");
  const [customPercentageText, setCustomPercentageText] = useState("");
  const [footerText, setFooterText] = useState("");
  const [editDisabled, setEditDisabled] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [messageDialogText, setMessageDialogText] = useState("");

  useEffect(() => {
    if (!id) return;

    if (id === "new") {
      setIsLoading(true);

      fetchLatestConfig()
        .then((config: Config) => {
          setHeaderText(config.headerText);
          setOptionZeroText(config.optionZeroText);
          setOptionZeroDesc(config.optionZeroDesc);
          setOptionMaxText(config.optionMaxText);
          setOptionMaxDesc(config.optionMaxDesc);
          setOptionCustomText(config.optionCustomText);
          setOptionCustomDesc(config.optionCustomDesc);
          setTaxModeHeaderText(config.taxModeHeaderText);
          setTaxModePreTaxText(config.taxModePreTaxText);
          setTaxModeAfterTaxText(config.taxModeAfterTaxText);
          setCustomHeaderText(config.customHeaderText);
          setCustomDollarsText(config.customDollarsText);
          setCustomPercentageText(config.customPercentageText);
          setFooterText(config.footerText);

          setIsLoading(false);
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setIsLoading(false);
        });
    } else {
      const configId: number = parseInt(id, 10);
      if (!configId) return;

      setIsLoading(true);

      fetchConfig(configId)
        .then((config: Config) => {
          setLoadedId(config.id);
          setYear(config.year.toString());
          setStartDate(config.startDate);
          setEndDate(config.endDate);
          setHeaderText(config.headerText);
          setOptionZeroText(config.optionZeroText);
          setOptionZeroDesc(config.optionZeroDesc);
          setOptionMaxText(config.optionMaxText);
          setOptionMaxDesc(config.optionMaxDesc);
          setOptionCustomText(config.optionCustomText);
          setOptionCustomDesc(config.optionCustomDesc);
          setTaxModeHeaderText(config.taxModeHeaderText);
          setTaxModePreTaxText(config.taxModePreTaxText);
          setTaxModeAfterTaxText(config.taxModeAfterTaxText);
          setCustomHeaderText(config.customHeaderText);
          setCustomDollarsText(config.customDollarsText);
          setCustomPercentageText(config.customPercentageText);
          setFooterText(config.footerText);
          setEditDisabled(!config.canUpdate);

          setIsLoading(false);
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setIsLoading(false);
        });
    }
  }, [id]);

  const saveHandler: MouseEventHandler<HTMLButtonElement> = () => {
    const data: ConfigData = {
      year: parseInt(year, 10),
      startDate: startDate,
      endDate: endDate,
      headerText,
      optionZeroText,
      optionZeroDesc,
      optionMaxText,
      optionMaxDesc,
      optionCustomText,
      optionCustomDesc,
      taxModeHeaderText,
      taxModePreTaxText,
      taxModeAfterTaxText,
      customHeaderText,
      customDollarsText,
      customPercentageText,
      footerText,
    };

    if (!loadedId) {
      apiCreateConfig(data)
        .then((result) => {
          if (result) {
            setMessageDialogText(
              "Please check the following fields: " +
                JSON.stringify(result.errors)
            );
            setMessageDialogOpen(true);
          } else {
            navigate("/configs");
          }
        })
        .catch((e) => {
          setErrorMessage(e.message);
        });
    } else {
      apiUpdateConfig(loadedId, data)
        .then((result) => {
          if (result) {
            setMessageDialogText(
              "Please check the following fields: " +
                JSON.stringify(result.errors)
            );
            setMessageDialogOpen(true);
          } else {
            navigate("/configs");
          }
        })
        .catch((e) => {
          setErrorMessage(e.message);
        });
    }
  };

  const handleDialogDismiss = () => {
    setMessageDialogOpen(false);
  };

  return (
    <AuthenticatedTemplate>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
      <Container maxWidth="lg" style={{ marginTop: 40 }}>
        <Paper elevation={3}>
          <Tabs value={tab}>
            <Tab
              component={Link}
              to={`/config/${id || "new"}/${ConfigPageTab.EDIT}`}
              replace={true}
              value={"edit"}
              label="Edit"
              disabled={editDisabled}
            />
            <Tab
              component={Link}
              to={`/config/${id || "new"}/${ConfigPageTab.PREVIEW}`}
              replace={true}
              value={"preview"}
              label="Preview"
            />
          </Tabs>
          <Box p={3}>
            {tab === ConfigPageTab.EDIT && (
              <>
                <TextField
                  label="Year"
                  value={year}
                  onChange={(event) => setYear(event.target.value)}
                  type="number"
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Start date"
                  value={dateToISODate(startDate)}
                  onChange={(event) => setStartDate(event.target.value)}
                  type="date"
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="End date"
                  value={dateToISODate(endDate)}
                  onChange={(event) => setEndDate(event.target.value)}
                  type="date"
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Header Text"
                  value={headerText}
                  onChange={(event) => setHeaderText(event.target.value)}
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Option Zero Text"
                  value={optionZeroText}
                  onChange={(event) => setOptionZeroText(event.target.value)}
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Option Zero Description"
                  value={optionZeroDesc}
                  onChange={(event) => setOptionZeroDesc(event.target.value)}
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Option Max Text"
                  value={optionMaxText}
                  onChange={(event) => setOptionMaxText(event.target.value)}
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Option Max Description"
                  value={optionMaxDesc}
                  onChange={(event) => setOptionMaxDesc(event.target.value)}
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Option Custom Text"
                  value={optionCustomText}
                  onChange={(event) => setOptionCustomText(event.target.value)}
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Option Custom Description"
                  value={optionCustomDesc}
                  onChange={(event) => setOptionCustomDesc(event.target.value)}
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Tax Mode Header Text"
                  value={taxModeHeaderText}
                  onChange={(event) => setTaxModeHeaderText(event.target.value)}
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Tax Mode Pre-Tax Text"
                  value={taxModePreTaxText}
                  onChange={(event) => setTaxModePreTaxText(event.target.value)}
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Tax Mode After-Tax Text"
                  value={taxModeAfterTaxText}
                  onChange={(event) =>
                    setTaxModeAfterTaxText(event.target.value)
                  }
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Custom Header Text"
                  value={customHeaderText}
                  onChange={(event) => setCustomHeaderText(event.target.value)}
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Custom Dollars Text"
                  value={customDollarsText}
                  onChange={(event) => setCustomDollarsText(event.target.value)}
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Custom Percentage Text"
                  value={customPercentageText}
                  onChange={(event) =>
                    setCustomPercentageText(event.target.value)
                  }
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Footer Text"
                  value={footerText}
                  onChange={(event) => setFooterText(event.target.value)}
                  variant="filled"
                  size="small"
                  margin="dense"
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                />
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={saveHandler}
                  >
                    Save
                  </Button>
                </Box>
              </>
            )}
            {tab === ConfigPageTab.PREVIEW && (
              <MainForm
                headerText={headerText}
                optionZeroText={optionZeroText}
                optionZeroDesc={optionZeroDesc}
                optionMaxText={optionMaxText}
                optionMaxDesc={optionMaxDesc}
                optionCustomText={optionCustomText}
                optionCustomDesc={optionCustomDesc}
                taxModeHeaderText={taxModeHeaderText}
                taxModePreTaxText={taxModePreTaxText}
                taxModeAfterTaxText={taxModeAfterTaxText}
                customHeaderText={customHeaderText}
                customDollarsText={customDollarsText}
                customPercentageText={customPercentageText}
                footerText={footerText}
              />
            )}
          </Box>
        </Paper>
      </Container>
      <MessageDialog
        open={messageDialogOpen}
        title="Error saving configuration"
        message={messageDialogText}
        dismiss="Dismiss"
        onDismiss={handleDialogDismiss}
      />
    </AuthenticatedTemplate>
  );
}
