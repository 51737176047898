import { AuthenticatedTemplate } from "@azure/msal-react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EmployeeInfoForm from "../Components/EmployeeInfoForm";
import MainForm from "../Components/MainForm";
import PrintableForm from "../Components/PrintableForm";
import { fetchEmployeeInfoWithConfig } from "../api";
import { Option, SpecificAmountType, TaxMode } from "../types";

export default function EmployeePage(): ReactElement {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [headerText, setHeaderText] = useState("");
  const [optionZeroText, setOptionZeroText] = useState("");
  const [optionZeroDesc, setOptionZeroDesc] = useState("");
  const [optionMaxText, setOptionMaxText] = useState("");
  const [optionMaxDesc, setOptionMaxDesc] = useState("");
  const [optionCustomText, setOptionCustomText] = useState("");
  const [optionCustomDesc, setOptionCustomDesc] = useState("");
  const [taxModeHeaderText, setTaxModeHeaderText] = useState("");
  const [taxModePreTaxText, setTaxModePreTaxText] = useState("");
  const [taxModeAfterTaxText, setTaxModeAfterTaxText] = useState("");
  const [customHeaderText, setCustomHeaderText] = useState("");
  const [customDollarsText, setCustomDollarsText] = useState("");
  const [customPercentageText, setCustomPercentageText] = useState("");
  const [footerText, setFooterText] = useState("");

  const [year, setYear] = useState(0);
  const [empCode, setEmpCode] = useState("");
  const [empName, setEmpName] = useState("");
  const [empEmail, setEmpEmail] = useState("");
  const [divName, setDivName] = useState("");
  const [ackDate, setAckDate] = useState<Date>();
  const [selectedOption, setSelectedOption] = useState<Option>();
  const [selectedTaxMode, setSelectedTaxMode] = useState<TaxMode>();
  const [selectedAmountType, setSelectedAmountType] =
    useState<SpecificAmountType>();
  const [selectedDollarAmount, setSelectedDollarAmount] = useState<number>();
  const [selectedPercentage, setSelectedPercentage] = useState<number>();

  useEffect(() => {
    if (!id) return;

    const employeeInfoId: number = parseInt(id, 10);
    if (!employeeInfoId) return;

    setIsLoading(true);
    fetchEmployeeInfoWithConfig(employeeInfoId)
      .then((data) => {
        setIsLoading(false);
        setErrorMessage("");

        setHeaderText(data.headerText);
        setOptionZeroText(data.optionZeroText);
        setOptionZeroDesc(data.optionZeroDesc);
        setOptionMaxText(data.optionMaxText);
        setOptionMaxDesc(data.optionMaxDesc);
        setOptionCustomText(data.optionCustomText);
        setOptionCustomDesc(data.optionCustomDesc);
        setTaxModeHeaderText(data.taxModeHeaderText);
        setTaxModePreTaxText(data.taxModePreTaxText);
        setTaxModeAfterTaxText(data.taxModeAfterTaxText);
        setCustomHeaderText(data.customHeaderText);
        setCustomDollarsText(data.customDollarsText);
        setCustomPercentageText(data.customPercentageText);
        setFooterText(data.footerText);

        setYear(data.year);
        setEmpCode(data.empCode);
        setEmpName(data.empName);
        setEmpEmail(data.empEmail);
        setDivName(data.divName);
        setAckDate(data.ackDate ? new Date(data.ackDate) : undefined);
        setSelectedOption(data.selectedOption);
        setSelectedTaxMode(data.selectedTaxMode);
        setSelectedAmountType(data.selectedAmountType);
        setSelectedDollarAmount(data.dollars);
        setSelectedPercentage(data.percentage);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(e.message);
      });
  }, [id]);

  return (
    <AuthenticatedTemplate>
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
      <Box displayPrint="none">
        <Container style={{ marginTop: 40 }}>
          <Paper elevation={3}>
            <Box p={3}>
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
              <MainForm
                disabled
                headerText={headerText}
                optionZeroText={optionZeroText}
                optionZeroDesc={optionZeroDesc}
                optionMaxText={optionMaxText}
                optionMaxDesc={optionMaxDesc}
                optionCustomText={optionCustomText}
                optionCustomDesc={optionCustomDesc}
                taxModeHeaderText={taxModeHeaderText}
                taxModePreTaxText={taxModePreTaxText}
                taxModeAfterTaxText={taxModeAfterTaxText}
                customHeaderText={customHeaderText}
                customDollarsText={customDollarsText}
                customPercentageText={customPercentageText}
                footerText={footerText}
                selectedOption={selectedOption}
                selectedTaxMode={selectedTaxMode}
                selectedAmountType={selectedAmountType}
                selectedDollarAmount={selectedDollarAmount}
                selectedPercentage={selectedPercentage}
              />
              <EmployeeInfoForm
                year={year}
                empCode={empCode}
                empName={empName}
                empEmail={empEmail}
                divName={divName}
                ackDate={ackDate}
              />
              <Box mt={2}>
                <Button variant="contained" onClick={() => window.print()}>
                  Print
                </Button>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Box>
      <Box display="none" displayPrint="contents">
        <PrintableForm
          headerText={headerText}
          optionZeroText={optionZeroText}
          optionZeroDesc={optionZeroDesc}
          optionMaxText={optionMaxText}
          optionMaxDesc={optionMaxDesc}
          optionCustomText={optionCustomText}
          optionCustomDesc={optionCustomDesc}
          taxModeHeaderText={taxModeHeaderText}
          taxModePreTaxText={taxModePreTaxText}
          taxModeAfterTaxText={taxModeAfterTaxText}
          customHeaderText={customHeaderText}
          customDollarsText={customDollarsText}
          customPercentageText={customPercentageText}
          footerText={footerText}
          selectedOption={selectedOption}
          selectedTaxMode={selectedTaxMode}
          selectedAmountType={selectedAmountType}
          selectedDollarAmount={selectedDollarAmount}
          selectedPercentage={selectedPercentage}
          year={year}
          empCode={empCode}
          empName={empName}
          empEmail={empEmail}
          divName={divName}
          ackDate={ackDate}
        />
      </Box>
    </AuthenticatedTemplate>
  );
}
