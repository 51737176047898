import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { ReactElement, ReactNode } from "react";
import ReactMarkdown from "react-markdown";
import { Option, SpecificAmountType, TaxMode } from "../types";

interface PrintableFormProps {
  headerText: string;
  optionZeroText: string;
  optionZeroDesc: string;
  optionMaxText: string;
  optionMaxDesc: string;
  optionCustomText: string;
  optionCustomDesc: string;
  taxModeHeaderText: string;
  taxModePreTaxText: string;
  taxModeAfterTaxText: string;
  customHeaderText: string;
  customDollarsText: string;
  customPercentageText: string;
  footerText: string;
  selectedOption: Option | undefined;
  selectedTaxMode: TaxMode | undefined;
  selectedAmountType: SpecificAmountType | undefined;
  selectedDollarAmount?: number;
  selectedPercentage?: number;
  year: number;
  empCode: string;
  empName: string;
  empEmail: string;
  divName: string;
  ackDate: Date | undefined;
}

export default function PrintableForm(props: PrintableFormProps): ReactElement {
  return (
    <div style={{ fontSize: "0.75rem" }}>
      <ReactMarkdown children={props.headerText} />
      <OptionDisplay
        label={props.optionZeroText}
        selected={props.selectedOption === Option.ZERO}
      >
        <ReactMarkdown children={props.optionZeroDesc} />
      </OptionDisplay>
      <OptionDisplay
        label={props.optionMaxText}
        selected={props.selectedOption === Option.MAX}
      >
        <ReactMarkdown children={props.optionMaxDesc} />
        <TaxModeDisplay
          headerText={props.taxModeHeaderText}
          preTaxOptionText={props.taxModePreTaxText}
          afterTaxOptionText={props.taxModeAfterTaxText}
          selectedTaxMode={props.selectedTaxMode}
        />
      </OptionDisplay>
      <OptionDisplay
        label={props.optionCustomText}
        selected={props.selectedOption === Option.SPECIFIC_AMOUNT}
      >
        <ReactMarkdown children={props.optionCustomDesc} />
        <TaxModeDisplay
          headerText={props.taxModeHeaderText}
          preTaxOptionText={props.taxModePreTaxText}
          afterTaxOptionText={props.taxModeAfterTaxText}
          selectedTaxMode={props.selectedTaxMode}
        />
        <AmountDisplay
          headerText={props.customHeaderText}
          dollarAmountOptionText={props.customDollarsText}
          percentageOptionText={props.customPercentageText}
          selectedAmountType={props.selectedAmountType}
          selectedDollarAmount={props.selectedDollarAmount}
          selectedPercentage={props.selectedPercentage}
        />
      </OptionDisplay>
      <ReactMarkdown children={props.footerText} />
      <table style={{ margin: "auto" }}>
        <tbody>
          <tr>
            <th align="right">Year:</th>
            <td>&nbsp;</td>
            <td align="left">{props.year}</td>
          </tr>
          <tr>
            <th align="right">Employee Code:</th>
            <td>&nbsp;</td>
            <td align="left">{props.empCode}</td>
          </tr>
          <tr>
            <th align="right">Employee Name:</th>
            <td>&nbsp;</td>
            <td align="left">{props.empName}</td>
          </tr>
          <tr>
            <th align="right">Employee Email:</th>
            <td>&nbsp;</td>
            <td align="left">{props.empEmail}</td>
          </tr>
          <tr>
            <th align="right">Division Name:</th>
            <td>&nbsp;</td>
            <td align="left">{props.divName}</td>
          </tr>
          <tr>
            <th align="right">Acknowledgment Date:</th>
            <td>&nbsp;</td>
            <td align="left">{props.ackDate?.toLocaleString() || "-"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

interface OptionDisplayProps {
  label: string;
  selected: boolean;
  children?: ReactNode;
}

function OptionDisplay(props: OptionDisplayProps) {
  return (
    <div>
      <div style={{ float: "left" }}>
        {props.selected ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
      </div>
      <div style={{ marginLeft: "2.5rem" }}>
        <ReactMarkdown children={props.label} />
        {props.selected && props.children}
      </div>
    </div>
  );
}

interface TaxModeDisplayProps {
  headerText: string;
  preTaxOptionText: string;
  afterTaxOptionText: string;
  selectedTaxMode: TaxMode | undefined;
}

function TaxModeDisplay(props: TaxModeDisplayProps) {
  return (
    <div>
      <ReactMarkdown children={props.headerText} />
      <OptionDisplay
        label={props.preTaxOptionText}
        selected={props.selectedTaxMode === TaxMode.PRE_TAX}
      />
      <OptionDisplay
        label={props.afterTaxOptionText}
        selected={props.selectedTaxMode === TaxMode.AFTER_TAX}
      />
    </div>
  );
}

interface AmountDisplayProps {
  headerText: string;
  dollarAmountOptionText: string;
  percentageOptionText: string;
  selectedAmountType: SpecificAmountType | undefined;
  selectedDollarAmount: number | undefined;
  selectedPercentage: number | undefined;
}

function AmountDisplay(props: AmountDisplayProps) {
  return (
    <div>
      <ReactMarkdown children={props.headerText} />
      <OptionDisplay
        label={props.dollarAmountOptionText}
        selected={props.selectedAmountType === SpecificAmountType.DOLLAR}
      >
        <p>${props.selectedDollarAmount}</p>
      </OptionDisplay>
      <OptionDisplay
        label={props.percentageOptionText}
        selected={props.selectedAmountType === SpecificAmountType.PERCENTAGE}
      >
        <p>{props.selectedPercentage}%</p>
      </OptionDisplay>
    </div>
  );
}
