import { AuthenticatedTemplate } from "@azure/msal-react";
import { GetApp, Sync, Visibility } from "@mui/icons-material";
import {
  Alert,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SelectConfig from "../Components/SelectConfig";
import TableFilterCell from "../Components/TableFilterCell";
import TableHeaderCell from "../Components/TableHeaderCell";
import {
  apiExportEmployeeInfoAsExcel,
  apiSyncEmployeesForConfig,
  apiUpdateEmployeeInfo,
  apiUpdateEmployeeInfos,
  fetchEmployeeInfoCount,
  fetchEmployeeInfos,
} from "../api";
import { useSearchParam, useSearchParamNumber } from "../hooks";
import {
  EmployeeInfo,
  EmployeeInfoOrder,
  Option,
  SpecificAmountType,
  TaxMode,
} from "../types";
import {
  formatAmount,
  formatDate,
  formatOption,
  formatTaxMode,
  saveBlob,
} from "../utils";

export default function EmployeesPage(): ReactElement {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isStoring, setIsStoring] = useState(false);
  const [data, setData] = useState<EmployeeInfo[]>([]);
  const [count, setCount] = useState(0);

  const [page, setPage] = useSearchParamNumber("page", 0);
  const [rowsPerPage, setRowsPerPage] = useSearchParamNumber("rowsPerPage", 25);
  const [order, setOrder] = useSearchParam<EmployeeInfoOrder>(
    "order",
    EmployeeInfoOrder.ID_ASC
  );
  const [configId, setConfigId] = useSearchParamNumber("configId", 0);
  const [empCode, setEmpCode] = useSearchParam<string>("empCode", "");
  const [empName, setEmpName] = useSearchParam<string>("empName", "");
  const [empEmail, setEmpEmail] = useSearchParam<string>("empEmail", "");
  const [divName, setDivName] = useSearchParam<string>("divName", "");
  const [option, setOption] = useSearchParam<Option | undefined>(
    "option",
    undefined
  );
  const [taxMode, setTaxMode] = useSearchParam<TaxMode | undefined>(
    "taxMode",
    undefined
  );
  const [amountType, setAmountType] = useSearchParam<
    SpecificAmountType | undefined
  >("amountType", undefined);

  useEffect(() => {
    fetchEmployeeInfoCount(
      configId ? configId : undefined,
      empCode,
      empName,
      empEmail,
      divName,
      option,
      taxMode,
      amountType
    )
      .then((count) => setCount(count))
      .catch((e) => setErrorMessage(e.message));
  }, [
    configId,
    empCode,
    empName,
    empEmail,
    divName,
    option,
    taxMode,
    amountType,
  ]);

  useEffect(() => {
    if (isStoring === true) return;

    setIsLoading(true);
    fetchEmployeeInfos(
      configId ? configId : undefined,
      empCode,
      empName,
      empEmail,
      divName,
      option,
      taxMode,
      amountType,
      order,
      page * rowsPerPage,
      rowsPerPage
    )
      .then((data) => {
        setErrorMessage("");
        setIsLoading(false);
        setData(data);
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setIsLoading(false);
        setData([]);
      });
  }, [
    configId,
    empCode,
    empName,
    empEmail,
    divName,
    option,
    taxMode,
    amountType,
    order,
    page,
    rowsPerPage,
    isStoring,
  ]);

  const includeAll = (checked: boolean) => {
    const ids: number[] = data.map((x) => x.id);
    setIsStoring(true);
    apiUpdateEmployeeInfos(ids, checked)
      .then(() => {
        setIsStoring(false);
        setErrorMessage("");
      })
      .catch((e) => {
        setIsStoring(false);
        setErrorMessage(e.message);
      });
  };

  const includeItem = (id: number, checked: boolean) => {
    setIsStoring(true);
    apiUpdateEmployeeInfo(id, checked)
      .then(() => {
        setIsStoring(false);
        setErrorMessage("");
      })
      .catch((e) => {
        setIsStoring(false);
        setErrorMessage(e.message);
      });
  };

  const handleExportToExcelClick = () => {
    apiExportEmployeeInfoAsExcel(
      configId ? configId : undefined,
      empCode,
      empName,
      empEmail,
      divName,
      option,
      taxMode,
      amountType,
      order
    )
      .then((blob) => saveBlob(blob, "Employees.xlsx"))
      .catch((e) => setErrorMessage(e.message));
  };

  const handleSyncEmployees = () => {
    if (!configId) return;

    setIsStoring(true);
    apiSyncEmployeesForConfig(configId)
      .then(() => {
        setIsStoring(false);
        setErrorMessage("");
      })
      .catch((e) => {
        setIsStoring(false);
        setErrorMessage(e.message);
      });
  };

  const totalCount: number = data.length;
  const includedCount: number = data.reduce(
    (count, x) => (x.isIncluded ? count + 1 : count),
    0
  );
  const excludedCount: number = data.reduce(
    (count, x) => (x.isIncluded ? count : count + 1),
    0
  );

  return (
    <AuthenticatedTemplate>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Backdrop open={isLoading || isStoring}>
        <CircularProgress />
      </Backdrop>
      <Container maxWidth={false} style={{ marginTop: 40 }}>
        <Box mb={2}>
          <Button
            color="secondary"
            variant="contained"
            size="small"
            startIcon={<GetApp />}
            onClick={handleExportToExcelClick}
          >
            Export to Excel
          </Button>
          &nbsp;&nbsp;
          <Button
            color="primary"
            variant="contained"
            size="small"
            startIcon={<Sync />}
            onClick={handleSyncEmployees}
            disabled={!configId}
          >
            Sync Employees
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table size="small" padding="checkbox">
            <TableHead>
              <TableRow key="headers">
                <TableHeaderCell
                  title="Year"
                  order={order}
                  orderAsc={EmployeeInfoOrder.CONFIG_YEAR_ASC}
                  orderDesc={EmployeeInfoOrder.CONFIG_YEAR_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Code"
                  order={order}
                  orderAsc={EmployeeInfoOrder.EMP_CODE_ASC}
                  orderDesc={EmployeeInfoOrder.EMP_CODE_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Name"
                  order={order}
                  orderAsc={EmployeeInfoOrder.EMP_NAME_ASC}
                  orderDesc={EmployeeInfoOrder.EMP_NAME_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Email"
                  order={order}
                  orderAsc={EmployeeInfoOrder.EMP_EMAIL_ASC}
                  orderDesc={EmployeeInfoOrder.EMP_EMAIL_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Division"
                  order={order}
                  orderAsc={EmployeeInfoOrder.DIV_NAME_ASC}
                  orderDesc={EmployeeInfoOrder.DIV_NAME_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Included"
                  order={order}
                  orderAsc={EmployeeInfoOrder.IS_INCLUDED_ASC}
                  orderDesc={EmployeeInfoOrder.IS_INCLUDED_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Acknowledge Date"
                  order={order}
                  orderAsc={EmployeeInfoOrder.ACK_DATE_ASC}
                  orderDesc={EmployeeInfoOrder.ACK_DATE_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Option"
                  order={order}
                  orderAsc={EmployeeInfoOrder.SELECTED_OPTION_ASC}
                  orderDesc={EmployeeInfoOrder.SELECTED_OPTION_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Tax Mode"
                  order={order}
                  orderAsc={EmployeeInfoOrder.SELECTED_TAX_MODE_ASC}
                  orderDesc={EmployeeInfoOrder.SELECTED_TAX_MODE_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Amount"
                  order={order}
                  orderAsc={EmployeeInfoOrder.SELECTED_AMOUNT_TYPE_ASC}
                  orderDesc={EmployeeInfoOrder.SELECTED_AMOUNT_TYPE_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableCell />
              </TableRow>
              <TableRow key="filters">
                <TableCell key="configfilter">
                  <SelectConfig
                    fullWidth
                    variant="standard"
                    emptyOptionLabel="All"
                    configId={configId}
                    onChange={(v) => setConfigId(v)}
                  />
                </TableCell>
                <TableFilterCell
                  value={empCode}
                  onChange={(v) => setEmpCode(v)}
                />
                <TableFilterCell
                  value={empName}
                  onChange={(v) => setEmpName(v)}
                />
                <TableFilterCell
                  value={empEmail}
                  onChange={(v) => setEmpEmail(v)}
                />
                <TableFilterCell
                  value={divName}
                  onChange={(v) => setDivName(v)}
                />
                <TableCell>
                  <Checkbox
                    checked={totalCount > 0 && includedCount === totalCount}
                    indeterminate={
                      excludedCount > 0 && excludedCount < totalCount
                    }
                    onChange={(_, checked) => includeAll(checked)}
                  />
                </TableCell>
                <TableCell />
                <TableCell>
                  <Select
                    fullWidth
                    variant="standard"
                    value={option || "ALL"}
                    onChange={(e) =>
                      setOption(
                        e.target.value !== "ALL"
                          ? (e.target.value as Option)
                          : undefined
                      )
                    }
                  >
                    <MenuItem value="ALL">All</MenuItem>
                    <MenuItem value="ZERO">Zero</MenuItem>
                    <MenuItem value="MAX">Max</MenuItem>
                    <MenuItem value="SPECIFIC_AMOUNT">Specific</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    fullWidth
                    variant="standard"
                    value={taxMode || "ALL"}
                    onChange={(e) =>
                      setTaxMode(
                        e.target.value !== "ALL"
                          ? (e.target.value as TaxMode)
                          : undefined
                      )
                    }
                  >
                    <MenuItem value="ALL">All</MenuItem>
                    <MenuItem value="PRE_TAX">Pre-tax</MenuItem>
                    <MenuItem value="AFTER_TAX">After tax</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    fullWidth
                    variant="standard"
                    value={amountType || "ALL"}
                    onChange={(e) =>
                      setAmountType(
                        e.target.value !== "ALL"
                          ? (e.target.value as SpecificAmountType)
                          : undefined
                      )
                    }
                  >
                    <MenuItem value="ALL">All</MenuItem>
                    <MenuItem value="DOLLAR">Dollar</MenuItem>
                    <MenuItem value="PERCENTAGE">Percentage</MenuItem>
                  </Select>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.year}</TableCell>
                  <TableCell>{row.empCode}</TableCell>
                  <TableCell>{row.empName}</TableCell>
                  <TableCell>{row.empEmail}</TableCell>
                  <TableCell>{row.divName}</TableCell>
                  <TableCell>
                    <Checkbox
                      disabled={!row.canUpdate}
                      checked={row.isIncluded}
                      onChange={(_, checked) => includeItem(row.id, checked)}
                    />
                  </TableCell>
                  <TableCell>{formatDate(row.ackDate)}</TableCell>
                  <TableCell>{formatOption(row.selectedOption)}</TableCell>
                  <TableCell>{formatTaxMode(row.selectedTaxMode)}</TableCell>
                  <TableCell>{formatAmount(row)}</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={"/employee/" + row.id}
                      color="primary"
                      size="small"
                      startIcon={<Visibility />}
                    >
                      Show
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          showFirstButton={true}
          showLastButton={true}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={(_, p) => setPage(p)}
          onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
        />
      </Container>
    </AuthenticatedTemplate>
  );
}
